<template>
  <b-form-group
    class="control-multiselect"
    :label="control.label"
    :label-for="control.id"
    :label-sr-only="control.labelSrOnly"
    :label-cols="control.labelCols"
    :label-cols-sm="control.labelColsSM"
    :label-cols-md="control.labelColsMD"
    :label-cols-lg="control.labelColsLG"
    :label-cols-xl="control.labelColsXL"
    :label-align="control.labelAlign"
    :label-align-sm="control.labelAlignSM"
    :label-align-md="control.labelAlignMD"
    :label-align-lg="control.labelAlignLG"
    :label-align-xl="control.labelAlignXL"
    :class="formGroupClasses"
    :description="control.description"
    :state="controlState"
  >
    <b-input-group
      :size="control.size"
      :class="inputGroupClasses"
    >
      <template v-if="$slots.prepend" #prepend><slot name="prepend"/></template>

      <div class="control-wrapper">
        <multiselect
          :id="control.id"
          :class="controlClasses"
          :name="control.name"
          :form="control.form"
          v-model="control.value"
          :options="control.options"
          :placeholder="control.placeholder"

          multiple
          label="text"
          track-by="value"
          preserve-search
          :close-on-select="false"
          :clear-on-select="false"
          :showLabels="false"
          :tabindex="control.tabindex"

          :disabled="control.disabled"
          :state="controlState"

          @input="onControlInput"

          @focus.native.capture="control.hasFocus = true"
          @blur.native.capture="control.hasFocus = false"
        >
          <template #tag="slotProps">
            <b-badge class="tag">{{ slotProps.option.text }}<a class="remove badge" @click="slotProps.remove(slotProps.option.value)"><b-icon icon="close"/></a></b-badge>
          </template>

          <template #noResult>
            <b-icon icon="questionmark"/>
          </template>
        </multiselect>
        <label v-if="!control.labelHide" :for="control.id" aria-hidden="true">{{ control.label }}</label>
      </div>

      <template v-if="$slots.append" #append><slot name="append"/></template>
    </b-input-group>

    <b-form-valid-feedback v-if="control.feedbacksValid" :state="controlState">{{ control.feedbacksValid }}</b-form-valid-feedback>
    <b-form-invalid-feedback :state="controlState">{{ controlInvalidFeedbacks }}</b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'ControlMultiselect',
  components: {
    Multiselect
  },
  props: {
    id: { type: String, required: true },
    name: { type: String, default: '' },
    form: { type: String, default: '' },
    formGroupClass: { type: [String, Object, Array], default: '' },
    inputGroupClass: { type: [String, Object, Array], default: '' },
    controlClass: { type: [String, Object, Array], default: '' },
    tabindex: { type: [String, Number], default: null },
    label: { type: String, default: '' },
    labelSrOnly: { type: Boolean, default: true },
    labelHide: { type: Boolean, default: false },
    labelCols: { type: [Number, String, Boolean], default: null },
    labelColsSM: { type: [Number, String, Boolean], default: null },
    labelColsMD: { type: [Number, String, Boolean], default: null },
    labelColsLG: { type: [Number, String, Boolean], default: null },
    labelColsXL: { type: [Number, String, Boolean], default: null },
    labelAlign: { type: String, default: '' },
    labelAlignSM: { type: String, default: '' },
    labelAlignMD: { type: String, default: '' },
    labelAlignLG: { type: String, default: '' },
    labelAlignXL: { type: String, default: '' },
    value: { type: Array, default: () => ([]) },
    options: { type: [Object, Array], required: true, default: () => ([]) },
    placeholder: { type: String, default: '' },
    description: { type: String, default: '' },
    size: { type: String, default: '' },
    dirty: { type: Boolean, default: false },
    state: { type: Boolean, default: null },
    readonly: { type: Boolean, default: false },
    plain: { type: Boolean, default: false },
    plaintext: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    validations: { type: Object, default: () => ({}) },
    feedbacksValid: { type: String, default: '' },
    feedbacksInvalid: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      control: {
        id: this.$props.id,
        name: this.$props.name,
        form: this.$props.form,
        formGroupClass: this.$props.formGroupClass,
        inputGroupClass: this.$props.inputGroupClass,
        controlClass: this.$props.controlClass,
        tabindex: this.$props.tabindex,
        label: this.$props.label,
        labelSrOnly: this.$props.labelSrOnly,
        labelHide: this.$props.labelHide,
        labelCols: this.$props.labelCols,
        labelColsSM: this.$props.labelColsSM,
        labelColsMD: this.$props.labelColsMD,
        labelColsLG: this.$props.labelColsLG,
        labelColsXL: this.$props.labelColsXL,
        labelAlign: this.$props.labelAlign,
        labelAlignSM: this.$props.labelAlignSM,
        labelAlignMD: this.$props.labelAlignMD,
        labelAlignLG: this.$props.labelAlignLG,
        labelAlignXL: this.$props.labelAlignXL,
        value: this.normalizeValue(this.$props.value),
        options: this.$props.options,
        placeholder: this.$props.placeholder,
        description: this.$props.description,
        size: this.$props.size,
        dirty: this.$props.dirty,
        state: this.$props.state,
        readonly: this.$props.readonly,
        plain: this.$props.plain,
        plaintext: this.$props.plaintext,
        disabled: this.$props.disabled,
        autofocus: this.$props.autofocus,
        validations: this.$props.validations,
        feedbacksValid: this.$props.feedbacksValid,
        feedbacksInvalid: this.$props.feedbacksInvalid,
        hasFocus: false
      }
    }
  },
  validations () {
    return {
      control: {
        value: this.control.validations
      }
    }
  },
  computed: {
    validator () {
      return this.$v.control.value
    },
    controlCanValidate () {
      if (this.validator !== undefined) return this.validator.$model !== undefined ? Object.keys(this.validator.$params).length > 0 : false

      return false
    },
    controlState () {
      if (this.controlCanValidate) return this.validator.$dirty ? !this.validator.$error : null
      if (this.control.state !== null) return this.control.dirty ? this.control.state : null
      return this.control.dirty ? true : null
    },
    controlInvalidFeedbacks () {
      if (this.controlCanValidate) {
        const validations = Object.keys(this.validator.$params || {})
        const feedbacks = this.feedbacksInvalid || {}
        const error = validations.find(key => this.validator[key] === false) || ''

        return this.validator.$error ? feedbacks[error] instanceof Function ? feedbacks[error]() : feedbacks[error] || '' : ''
      }

      if (this.control.dirty && !this.control.state) {
        return this.feedbacksInvalid.error || ''
      }

      return ''
    },
    formGroupClasses () {
      return [
        {
          'has-focus': this.control.hasFocus,
          'has-prepend': this.$slots.prepend,
          'has-append': this.$slots.append,
          'is-filled': this.control.value !== undefined && this.control.value !== null && this.control.value.length > 0,
          'is-readonly': this.control.readonly,
          'is-plaintext': this.control.plaintext,
          'is-disabled': this.control.disabled,
          'is-dirty': this.controlCanValidate ? this.validator.$dirty : false
        }
      ].concat(this.control.formGroupClass || [])
    },
    inputGroupClasses () {
      return [
        {
          'mb-0': this.control.label === ''
        }
      ].concat(this.control.formInputClass || [])
    },
    controlClasses () {
      return ['custom-select'].concat({
        [`custom-select-${this.control.size}`]: this.control.size
      }, this.control.controlClass || [])
    }
  },
  methods: {
    normalizeValue (value = []) {
      return value.map(v => ({ value: v, text: (this.options.find(o => o.value === v) || {}).text || '' }))
    },
    unwrapValue (value = {}) {
      return value.value || null
    },
    setDirty (dirty) {
      if (this.controlCanValidate) {
        if (dirty) {
          this.validator.$touch()
        } else {
          this.validator.$reset()
        }
      }
    },
    onControlInput (controlValue) {
      const unwrappedValue = controlValue.map(this.unwrapValue)

      if (this.controlCanValidate && this.validator.$dirty) {
        this.validator.$model = unwrappedValue
        this.validator.$touch()
      }

      this.control.state = null

      this.$emit('input', unwrappedValue)
      this.$emit('change', unwrappedValue)
    }
  },
  created () {
    this.setDirty(this.control.dirty)
  },
  mounted () {
    this.$nextTick(() => {
      this.$root.$emit('control:ready', Object.assign(this.control, { $touch: this.validator.$touch, $reset: this.validator.$reset }))
    })
  },
  watch: {
    '$props.id' (id) { this.control.id = id },
    '$props.name' (name) { this.control.name = name },
    '$props.form' (form) { this.control.form = form },
    '$props.formGroupClass' (formGroupClass) { this.control.formGroupClass = formGroupClass },
    '$props.inputGroupClass' (inputGroupClass) { this.control.inputGroupClass = inputGroupClass },
    '$props.controlClass' (controlClass) { this.control.controlClass = controlClass },
    '$props.tabindex' (tabindex) { this.control.tabindex = tabindex },
    '$props.label' (label) { this.control.label = label },
    '$props.labelSrOnly' (labelSrOnly) { this.control.labelSrOnly = labelSrOnly },
    '$props.labelHide' (labelHide) { this.control.labelHide = labelHide },
    '$props.labelCols' (labelCols) { this.control.labelCols = labelCols },
    '$props.labelColsSM' (labelColsSM) { this.control.labelColsSM = labelColsSM },
    '$props.labelColsMD' (labelColsMD) { this.control.labelColsMD = labelColsMD },
    '$props.labelColsLG' (labelColsLG) { this.control.labelColsLG = labelColsLG },
    '$props.labelColsXL' (labelColsXL) { this.control.labelColsXL = labelColsXL },
    '$props.labelAlign' (labelAlign) { this.control.labelAlign = labelAlign },
    '$props.labelAlignSM' (labelAlignSM) { this.control.labelAlignSM = labelAlignSM },
    '$props.labelAlignMD' (labelAlignMD) { this.control.labelAlignMD = labelAlignMD },
    '$props.labelAlignLG' (labelAlignLG) { this.control.labelAlignLG = labelAlignLG },
    '$props.labelAlignXL' (labelAlignXL) { this.control.labelAlignXL = labelAlignXL },
    '$props.value' (value) { this.control.value = this.normalizeValue(value) },
    '$props.options' (options) { this.control.options = options },
    '$props.placeholder' (placeholder) { this.control.placeholder = placeholder },
    '$props.description' (description) { this.control.description = description },
    '$props.size' (size) { this.control.size = size },
    '$props.dirty' (dirty) {
      this.control.dirty = dirty
      this.setDirty(this.control.dirty)
    },
    '$props.state' (state) { this.control.state = state },
    '$props.readonly' (readonly) { this.control.readonly = readonly },
    '$props.plain' (plain) { this.control.plain = plain },
    '$props.plaintext' (plaintext) { this.control.plaintext = plaintext },
    '$props.disabled' (disabled) { this.control.disabled = disabled },
    '$props.autofocus' (autofocus) { this.control.autofocus = autofocus },
    '$props.validations' (validations) { this.control.validations = validations },
    '$props.feedbacksValid' (feedbacksValid) { this.control.feedbacksValid = feedbacksValid },
    '$props.feedbacksInvalid' (feedbacksInvalid) { this.control.feedbacksInvalid = feedbacksInvalid }
  }
}
</script>

<style lang="scss">
@import '~vue-multiselect/dist/vue-multiselect.min.css';

$multiselect-tags-margin: $spacer * 0.1 !default;

$multiselect-tags-tag-bg: $dark !default;
$multiselect-tags-tag-color: color-yiq($multiselect-tags-tag-bg) !default;

$multiselect-tags-tag-remove-gap: $spacer * 0.5 !default;
$multiselect-tags-tag-remove-padding: $badge-padding-y ($badge-padding-x * 0.5) !default;
$multiselect-tags-tag-remove-bg: rgba($black, 0.4) !default;
$multiselect-tags-tag-remove-color: inherit !default;
$multiselect-tags-tag-remove-hover-bg: rgba($danger, 0.8) !default;

$multiselect-tags-search-padding-y: $spacer * 0.2 !default;
$multiselect-tags-search-padding-x: 0 !default;

$multiselect-content-bg: $body-bg !default;
$multiselect-content-border: $gray-100 solid $border-width !default;
$multiselect-content-box-shadow: $box-shadow-sm !default;

$multiselect-content-element-option-padding-y: $spacer * 0.2 !default;
$multiselect-content-element-option-padding-x: $spacer * 0.6 !default;
$multiselect-content-element-option-min-height: calc(#{$multiselect-content-element-option-padding-y * 2} + #{$line-height-base}em) !default;
$multiselect-content-element-option-hover-bg: theme-color-level('new', -6) !default;
$multiselect-content-element-option-selected-bg: $gray-200 !default;
$multiselect-content-element-option-selected-hover-bg: theme-color-level('delete', -6) !default;

.control-multiselect {
  position: relative;

  .control-wrapper {
    .custom-select {
      position: relative;
      height: auto;
      min-height: $custom-select-height;
      box-sizing: border-box;
      z-index: 1;

      + label {
        z-index: 2;
      }

      .multiselect__select {
        display: none;
      }

      .multiselect__tags {
        padding: 0;
        border: 0 none;
        min-height: 0;
        background: none;
        border-radius: 0;

        .multiselect__tags-wrap {
          display: flex;
          flex-wrap: wrap;
          margin: $multiselect-tags-margin * -1;

          $badge-group-badge-margin: $spacer * 0.1 !default;

          .tag {
            display: flex;
            justify-content: space-between;
            margin: $multiselect-tags-margin;
            background-color: $multiselect-tags-tag-bg;
            color: $multiselect-tags-tag-color;

            .remove {
              display: block;
              margin: ($badge-padding-y * -1) ($badge-padding-x * -1);
              margin-left: $multiselect-tags-tag-remove-gap;
              padding: $multiselect-tags-tag-remove-padding;
              background-color: $multiselect-tags-tag-remove-bg;
              font-size: 100%;
              color: $multiselect-tags-tag-remove-color;
              cursor: pointer;

              &:hover {
                background-color: $multiselect-tags-tag-remove-hover-bg;
              }
            }
          }
        }

        .multiselect__input {
          margin: 0;
          padding: $multiselect-tags-search-padding-y $multiselect-tags-search-padding-x;
          background: none;
          border-radius: 0;
        }
      }

      .multiselect__content-wrapper {
        left: 0;
        right: 0;
        background-color: $multiselect-content-bg;
        border: $multiselect-content-border;
        border-radius: 0;
        box-shadow: $multiselect-content-box-shadow;

        .multiselect__content {
          display: block!important;;

          .multiselect__element {
            .multiselect__option {
              padding: $multiselect-content-element-option-padding-y $multiselect-content-element-option-padding-x;
              min-height: $multiselect-content-element-option-min-height;
              line-height: inherit;

              &.multiselect__option--highlight {
                background-color: $multiselect-content-element-option-hover-bg;
              }

              &.multiselect__option--selected {
                background-color: $multiselect-content-element-option-selected-bg;
                font-weight: inherit;

                &.multiselect__option--highlight {
                  background-color: $multiselect-content-element-option-selected-hover-bg;
                }
              }
            }
          }
        }
      }

      &.form-control-sm {
        min-height: $custom-select-height-sm;
      }

      &.form-control-lg {
        min-height: $custom-select-height-lg;
      }
    }
  }

  &.has-focus {
    z-index: 1000;

    .control-wrapper {
      .custom-select {
        z-index: 3;
      }
    }
  }
}
</style>
